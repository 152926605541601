<template>
  <travio-center-container grid-width="2/3" pageTitle="Edit Company">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="tabIndex" class="tabs-shadow-none">
          <!-- COMPANY INFO -->
          <vs-tab class="fixed-height" icon-pack="feather" icon="icon-settings" label="Company Info">
            <div class="mt-4 ml-0 mr-0">
              <div class="flex flex-wrap items-center mb-base">
                <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
                <div>
                  <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
                  <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload logo</vs-button>
                  <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
                  <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Company Name*</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input class="w-full" v-model="form.companyName" v-validate="'required|max:100'" name="Company Name" />
                  <span class="text-danger text-sm">{{ errors.first('Company Name') }}</span>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Company Type</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <v-select :value="selectedCompanyType" @input="setSelectedCompanyType" placeholder="CompanyType" :options="companyTypeOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Address 1</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input class="w-full" v-model="form.address1" name="Address 1"  v-validate="'max:250'" />
                  <span class="text-danger text-sm">{{ errors.first('Address 1') }}</span>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Address 2</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input class="w-full" v-model="form.address2" name="Address 2"  v-validate="'max:250'" />
                  <span class="text-danger text-sm">{{ errors.first('Address 2') }}</span>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>City</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input class="w-full" v-model="form.city" name="City"  v-validate="'max:50'" />
                  <span class="text-danger text-sm">{{ errors.first('City') }}</span>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>County / State</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input class="w-full" v-model="form.state" name="State"  v-validate="'max:50'" />
                  <span class="text-danger text-sm">{{ errors.first('State') }}</span>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Country</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <v-select class="style-chooser" :value="selectedCountry" append-to-body @input="setSelectedCountry" placeholder="Country" :options="countryOptions" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Require 2FA</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full ml-auto">
                  <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
                  <vs-switch class="" v-model="form.requires2FA" />
                </div>
              </div>

              <div class="lg:float-left mt-4">
                <span class="text-sm text-danger">*Required Field</span>
              </div>

              <div class="flex flex-wrap justify-end">
                <vs-button @click='onCancel' color="danger" type="flat">Cancel</vs-button>
                <vs-button @click='save'>Save</vs-button>
              </div>
            </div>
          </vs-tab>

          <vs-tab class="fixed-height-company" icon-pack="feather" icon="icon-user" label="Users">
            <company-users :companyId="companyId"></company-users>
          </vs-tab>
          <vs-tab class="fixed-height-company" icon-pack="feather" icon="icon-globe" label="Applications">
            <company-applications :companyId="companyId"></company-applications>
          </vs-tab>
        </vs-tabs>
      </div>
      

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import CompanyApplications from './CompanyApplications.vue'
import CompanyUsers from './CompanyUsers.vue'
import hostAdminCrmStore from './hostAdminCrmStore.js'

export default {
  components: {
    vSelect
  },
  components: {
    CompanyApplications,
    CompanyUsers
  },
  props: { 
    companyId: { required: true },
    presetTabIndex: { required: false, default: 0, type: Number }
  },
  data () {
    return {
      form: new Form({
        companyName: '',
        companyType: null,
        logoUrl: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        countryCode: null,
        requires2FA: false,
        logoFile: null
      }),
      selectedCountry: {},
      selectedCompanyType: {},
      tabIndex: 0
    }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    companyTypeOptions () {
      return this.$store.state.lookups.companyTypeOptions
    },
  },
  created () {
    this.tabIndex = this.presetTabIndex
  },
  mounted () {
    this.initCmrStore()
    this.$http
      .get(`api/admin/companies/${this.companyId}`)
      .then(response => { 
        this.form = new Form(Object.assign(this.form.data(), response.data))
        this.selectedCountry = this.countryOptions.find(x => x.code == response.data.countryCode) || {}
        this.selectedCompanyType = this.companyTypeOptions.find(x => x.code == response.data.companyType) || {}
      })
      .catch(error => {
        error.response.data || this.$_notifyFailure(error) && this.$_notifyFailureByResponseData(error.response.data)
      })

  },
  beforeRouteLeave (to, from, next) {
    // clean up store to prevent seeing previous data while fetching new data
    this.$store.commit('userStore/SET_USER_LIST', []);
    this.$store.commit('companyApplicationStore/SET_APPLICATION_LIST', []);
    next()
  },
  methods: {
    initCmrStore() {
      if (!hostAdminCrmStore.isRegistered) {
        this.$store.registerModule('hostAdminCrmStore', hostAdminCrmStore)
        hostAdminCrmStore.isRegistered = true
      }
      this.$store.commit('hostAdminCrmStore/SET_ACTIVE_COMPANY_ID', this.companyId)
    },
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedCompanyType (value) {
      this.form.companyType = value ? value.code : null
      this.selectedCompanyType = value;
    },
    handleFileUpload () {
       this.form.logoFile = this.$refs.companyLogoInput.files[0];
      // Temporary render file, can't upload yet since Application ID is not yet available
      if (this.form.logoFile) {
        this.form.logoUrl = URL.createObjectURL(this.form.logoFile);
      }
    },
    save () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData();
          const postData = this.form.data()
          let props = Object.keys(postData)
          props.forEach(key => {
            //skip nulls since it will be parsed as "null"
            postData[key] && formData.append(key, postData[key]);  
          })
          
          this.$vs.loading()
          this.$http.put( `api/admin/companies/${this.companyId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
          })
          .then(response => {
            this.$_notifySuccess('Company successfully updated.')
            this.$router.push({ name: 'admin-crm' })
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        }
      })
    },
    saveOld () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.$http.put( `api/admin/companies/${this.companyId}`, this.form.data())
          .then(response => {
            this.$_notifySuccess('Company successfully updated.')
            this.$router.push({ name: 'admin-crm' })
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        }
      })
    },
    onCancel () {
      this.$router.push({ name: 'admin-crm' })
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
      this.form.logoFile = null
    }
  }
}
</script>

<style scoped>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  .fixed-height-company {
    height: calc(var(--vh, 1vh) * 100 - 21.5rem);
    /* overflow-y: hidden; */
    overflow-x: hidden;
    position: relative;
  }
</style>